<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-card-title>VOUCHERS</v-card-title>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                style="position: absolute; top: 10px; right: 10px;"
              >
               New Voucher 
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="4">
                       <v-text-field
                         v-model="prefix"
                         label="Prefix"
                         :rules="rulesPrefix"
                       ></v-text-field> 
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select
                                  v-model="quantity"
                                  :items="quantities"
                                  label="Quantity"
                                ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="discount"
                          label="Discount"
                        ></v-text-field> 
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu
                          v-model="dateValidMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          min-width="auto"
                          clearable
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateValid"
                              label="Date Valid"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="primary"
                            v-model="dateValid"
                            @input="dateValidMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                       <v-menu
                         v-model="dateExpiredMenu"
                         :close-on-content-click="false"
                         transition="scale-transition"
                         min-width="auto"
                         clearable
                       >
                         <template v-slot:activator="{ on, attrs }">
                           <v-text-field
                             v-model="dateExpired"
                             label="Date Expired"
                             readonly
                             v-bind="attrs"
                             v-on="on"
                           ></v-text-field>
                         </template>
                         <v-date-picker
                           color="primary"
                           v-model="dateExpired"
                           @input="dateExpiredMenu = false"
                         ></v-date-picker>
                       </v-menu>
                      </v-col>
                      
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="primary darken-1 white--text" @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-subtitle>Manage Vouchers </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="compVouchers"
                    class="elevation-0"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2">
                            <v-switch
                              v-model="showUsed"
                              label="Show Used?"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.code="{ item }">
                        <v-btn text color="primary darken-1" text @click="copyCode(item.code)">{{ item.code }}</v-btn>
                    </template>
                    <template v-slot:item.dateValid="{ item }">
                        <FancyDate :date="item.dateValid" />
                    </template>
                    <template v-slot:item.dateExpired="{ item }">
                        <FancyDate :date="item.dateExpired" />
                    </template>
                    <template v-slot:item.createdOn="{ item }">
                        <FancyDate :date="item.createdOn" />
                    </template>
                    <template v-slot:item.dateUsed="{ item }">
                        <FancyDate v-if="item.dateUsed" :date="item.dateUsed" />
                    </template>
                    <template v-slot:item.customer="{ item }">
                        <div v-if="item.customer">
                        <router-link v-bind:to="'/users/' + item.customer._id">{{ item.customer.firstname }}
                            {{ item.customer.lastname }}</router-link>
                         </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
import PaymentLinkCard from "@/components/paymentLinkCard.vue";
import FancyDate from "@/components/fancyDate.vue";
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
export default {
  name: "Vouchers",
  components: {
    PaymentLinkCard,
    FancyDate
  },
  data: () => ({
    dateValidMenu: false,
    dateValid: null,
    dateExpiredMenu: false,
    dateExpired: null,
    // code: null,
    // amount: null,
    customerID: null,
    activePicker: null,
    date: null,
    menu: false,
    valid: true,
    search: "",
    roleFilter: "",
    expanded: [],
    showDisabled: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Code", value: "code" },
      { text: "Discount", value: "discount" },
      { text: "Date Valid", value: "dateValid" },
      { text: "Date Expired", value: "dateExpired" },
      { text: "Created", value: "createdOn" },
      { text: "Used", value: "dateUsed" },
      { text: "Customer", value: "customer" },
    ],
    editedIndex: -1,
    editedItem: {
      customer: null,
      bookingNumber: null
    },
    email: null,
    firstname: null,
    lastname: null,
    loading: true,
    showUsed: false,
    rulesPrefix: [
            // value => !!value || 'Required.',
            value => (value || '').length <= 3 || 'Max 3 characters',
            // value => {
            //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            //   return pattern.test(value) || 'Invalid e-mail.'
            // },
          ],
    quantities: [1,5,10,50],
    prefix: null,
    quantity: null,
    discount: null,
    vouchers: []
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    users() {
      return this.$store.state.users;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Voucher" : "Edit Voucher";
    },
    compUsers() {
      let users = this.users;
      users = users.filter(u => u.role === "customer");
      users.map(
        u =>
          (u.fullname =
            u.firstname +
            " " +
            u.lastname +
            this.parseCompanyName(u.companyName))
      );
      return users;
    },
    compVouchers() {
      let v = this.vouchers;

      for (var i = v.length - 1; i >= 0; i--) {
        v[i].customer = this.users.find(
          u => u._id === v[i].usedBy
        );
      }

      // if show used === false, remove the used vouchers
      if(this.showUsed===false) {
        v = v.filter(voucher => {
          return typeof(voucher.usedBy) === 'undefined'
        })
      }

      return v;

    },
    // compPaymentLinks() {
    //   let links = this.paymentLinks;
    //   if (this.showPaid) return links;
    //   links = links.filter(l => typeof l.charge === "undefined");
    //   return links;
    // },
    compCustomerBookings() {
      let bookings = this.$store.state.bookings;
      bookings = bookings.filter(
        b => b.customerID === this.editedItem.customer
      );
      return bookings;
    }
  },
  methods: {
      copyCode(code) {
        navigator.clipboard.writeText(code);
        this.$toasted.show("Voucher copied").goAway(2500);
      },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // addLineItem() {
    //   let lineItem = {
    //     id: moment().unix(),
    //     service: this.service,
    //     quantity: this.quantity,
    //     price: this.price,
    //     notes: this.notes
    //   };
    //   this.lineItems.push(lineItem);
    //   // clear line inputs
    //   this.service = null;
    //   this.quantity = null;
    //   this.price = null;
    //   this.notes = null;
    // },
    // deleteLineItem(item) {
    //   console.log(item);
    //   this.lineItems = this.lineItems.filter(l => l.id !== item.id);
    // },
    // multiply(x, y) {
    //   return Number(x) * Number(y);
    // },
    // total(lines) {
    //   let total = 0;
    //   if (lines) {
    //     lines.forEach(
    //       l => (total = total + this.multiply(l.quantity, l.price))
    //     );
    //   }
    //   return total;
    // },
    // parseCompanyName(companyName) {
    //   if (companyName) {
    //     return ` (${companyName})`;
    //   }
    //   return "";
    // },
    save() {
      let vm = this;
      let voucher = {
        prefix: vm.prefix,
        quantity: vm.quantity,
        discount: vm.discount,
        dateValid: vm.dateValid,
        dateExpired: vm.dateExpired
      };
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      if (this.$refs.form.validate()) {
        // save new paylink via API
        axios
          .post(vm.$root.urls.api + "/v3/voucher", voucher )
          .then(function(results) {
            // console.log(results.data);
            vm.getVouchers();
            // clear inputs
            vm.prefix = null;
            vm.quantity = null;
            vm.discount = null;
            vm.dateValid = null;
            vm.dateExpired = null;

            // toast
            vm.$toasted.show("Voucher(s) added").goAway(2500);
          })
          .catch(function(error) {
            console.log(error);
            // toast
            vm.$toasted.show("Unable to add voucher(s)").goAway(2500);
          });
        this.close();
      }
    },
    getVouchers() {
      let vm = this;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .get(vm.$root.urls.api + "/v3/vouchers", {})
        .then(function(results) {
          // console.log(results.data);
          vm.vouchers = results.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    }
  },
  mounted: function() {
    this.getVouchers();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: {
      handler(val) {
        if (val.customer) {
          // set email
          let data = this.users.find(u => u._id === val.customer);
          this.email = data.username;
          this.firstname = data.firstname;
          this.lastname = data.lastname;
        } else {
          this.email = null;
          this.firstname = null;
          this.lastname = null;
        }
      },
      deep: true
    }
    // dialogDelete(val) {
    //     val || this.closeDelete();
    // },
    // editedItem(val) {
    //     console.log(val)
    // },
  }
};
</script>
<style>
.table {
  width: 100%;
}
.table tr td:nth-child(1) {
  width: 50%;
}
.table tr td:nth-child(2) {
  width: 15%;
}
.table tr td:nth-child(3) {
  width: 15%;
}
.table tr td:nth-child(4) {
  width: 20%;
}
</style>
